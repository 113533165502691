html,
body {
    width: 100%;
    /* height: 100%; */
    margin: 0px;
    padding: 0px;
    overflow-x: hidden;

    --color-neutral-100: white;
    --color-light-200: #c8c8c8;
    --color-green-400: #34a817;
    --color-blue-200: #0ebfcf;
    font-family: "Noto Sans", sans-serif;

    --color-cold-blue: #21094E;
    --color-cold-purple: #511281;
    --color-cold-teal: #4CA1A3;
    --color-cold-green: #A5E1AD;

    --color-dark-black: #222831;
    --color-dark-space: #393E46;
    --color-dark-yellow: #FFD369;
    --color-dark-grey: #EEEEEE
}

.mono {
    font-family: "Fira Code", monospace;
}

.white {
    color: var(--color-neutral-100);
}

.grey {
    color: var(--color-light-200);
}

.logo {
    color: var(--color-neutral-100);
}

.logo:hover {
    color: dodgerblue;
    cursor: pointer;
}

.hover:hover {
    cursor: pointer;
}

.text-center {
    text-align: center;
}

.split {
    display: flex;
    padding-left: 15%;
    padding-right: 15%;
}

.left {
    width: 40%;
    text-align: right;
    padding-right: min(5rem, 10%);
}

.right {
    width: 60%;
}

.tagline { 
    color: var(--color-green-400);
    font-size: 1rem;
}

.darklink {
    color: var(--color-blue-200);
}

.bold {
    font-weight: bold;
    color: red;
}

@media only screen and (max-width: 1024px) {
    .split {
        display: block;
    }

    .left {
        width: 100%;
        text-align: center;
        padding-right: 0;
        padding-bottom: 1.5rem
    }

    .right {
        width: 100%;
        text-align: center;
    }
}


.vertical-timeline.customline::before {
    background: yellow;
    background-color: var(--color-dark-yellow);
}

.projectCardContainer:hover .projectCard {
    opacity: 0.3;
}

.projectCardContainer:hover .projectCardOverlay {
    opacity: 1;
}

.projectCard {
    width: 20rem;
    overflow: hidden;
    border-radius: 10px;

    opacity: 1;
    display: block;
    transition: .5s ease;
    backface-visibility: hidden;
}

.projectCardContainer {
    width: 25rem;
}

.projectCardOverlay {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 5%;
    left: 5%;
    right: 5%;
    bottom: 5%;
    width: 20rem;
    align-items: center;
    padding-right: 10%;
}